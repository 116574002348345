// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-shared-templates-blog-template-js": () => import("/opt/build/repo/src/shared/templates/BlogTemplate.js" /* webpackChunkName: "component---src-shared-templates-blog-template-js" */),
  "component---src-shared-templates-location-template-js": () => import("/opt/build/repo/src/shared/templates/LocationTemplate.js" /* webpackChunkName: "component---src-shared-templates-location-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-now-gateshead-cleaners-js": () => import("/opt/build/repo/src/pages/book-now/gateshead-cleaners.js" /* webpackChunkName: "component---src-pages-book-now-gateshead-cleaners-js" */),
  "component---src-pages-book-now-index-js": () => import("/opt/build/repo/src/pages/book-now/index.js" /* webpackChunkName: "component---src-pages-book-now-index-js" */),
  "component---src-pages-book-now-newcastle-upon-tyne-cleaners-js": () => import("/opt/build/repo/src/pages/book-now/newcastle-upon-tyne-cleaners.js" /* webpackChunkName: "component---src-pages-book-now-newcastle-upon-tyne-cleaners-js" */),
  "component---src-pages-book-now-north-tyneside-cleaners-js": () => import("/opt/build/repo/src/pages/book-now/north-tyneside-cleaners.js" /* webpackChunkName: "component---src-pages-book-now-north-tyneside-cleaners-js" */),
  "component---src-pages-cleaning-services-commercial-cleaning-js": () => import("/opt/build/repo/src/pages/cleaning-services/commercial-cleaning.js" /* webpackChunkName: "component---src-pages-cleaning-services-commercial-cleaning-js" */),
  "component---src-pages-cleaning-services-deep-cleaning-js": () => import("/opt/build/repo/src/pages/cleaning-services/deep-cleaning.js" /* webpackChunkName: "component---src-pages-cleaning-services-deep-cleaning-js" */),
  "component---src-pages-cleaning-services-domestic-house-cleans-js": () => import("/opt/build/repo/src/pages/cleaning-services/domestic-house-cleans.js" /* webpackChunkName: "component---src-pages-cleaning-services-domestic-house-cleans-js" */),
  "component---src-pages-cleaning-services-end-of-tenancy-cleaning-js": () => import("/opt/build/repo/src/pages/cleaning-services/end-of-tenancy-cleaning.js" /* webpackChunkName: "component---src-pages-cleaning-services-end-of-tenancy-cleaning-js" */),
  "component---src-pages-cleaning-services-index-js": () => import("/opt/build/repo/src/pages/cleaning-services/index.js" /* webpackChunkName: "component---src-pages-cleaning-services-index-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("/opt/build/repo/src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

